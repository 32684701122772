import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faUser,
   faBagShopping,
   faMagnifyingGlass,
   faBars
} from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from 'react-i18next';
import cartImage from "../../../../assets/images/cart.webp";
import styles from "./style.module.scss";
import { LANGUAGES } from "../../../../utils/constants";

const cx = classNames.bind(styles);

const icons = {
   user: faUser,
   shopping: faBagShopping,
   search: faMagnifyingGlass,
   bars: faBars
} as any;

function Header(props: any) {
   const { t, language, handleChangeLanguage } = props
   return (
      <div className="_header-wrapper flex justify-between items-center bg-[#f1f1f1] p-[15px] md:p-[10px] md:justify-between sm:p-[10px]">
         <div className="_cart md:hidden xl:hidden sm:w-1/3">
            <img
               className="sm:h-5 "
               src={
                  cartImage
               }
               alt=""
            />
         </div>
         <div className="sm:w-1/3">
            <Link to={'/'} className="sm:text-center">
               <img
                  className="sm:w-[100px] md:w-[130px] xl:w-[250px]"
                  src={
                     "https://bizweb.sapocdn.net/100/369/010/themes/845311/assets/logo.png?1660113439586"
                  }
                  alt=""
               />
            </Link>
         </div>
         <ul className="sm:hidden md:hidden flex m-0 items-center gap-x-[10px]">
            <li className={cx("menu-item")}>
               <Link to={"/shop"}>{t('menu.shop')}</Link>
            </li>
            <li className={cx("menu-item")}>
               <Link to={"/"}>sale</Link>
            </li>
            <li className={cx("menu-item")}>
               <Link to={"/"}>news</Link>
            </li>
            <li className={cx("menu-item")}>
               <Link to={"/"}>contact</Link>
            </li>
            <li className={cx("menu-item")}>
               <Link to={"/"}>about</Link>
            </li>
            <li className={cx("menu-item")}>
               <Link to={"/"}>collab's</Link>
            </li>
            <li className={cx("menu-item")}>
               <Link to={"/"}>one piece</Link>
            </li>
         </ul>
         <div className="flex sm:flex sm:w-1/3 sm:justify-end md:flex">
            <div className="_language mr-5 sm:mr-3 flex gap-2 items-center sm:block">
               <span
                  className={`${language === LANGUAGES.VN && 'border-solid border-b border-[#3d4246]'} sm:mr-2 cursor-pointer font-medium`}
                  onClick={() => handleChangeLanguage(LANGUAGES.VN)}>
                  Vi
               </span>
               <span
                  className={`${language === LANGUAGES.EN && 'border-solid border-b border-[#3d4246]'} cursor-pointer font-medium`}
                  onClick={() => handleChangeLanguage(LANGUAGES.EN)}>
                  En
               </span>
            </div>
            <FontAwesomeIcon className={cx("bars")} icon={icons.bars} fontSize={25} />

            <div className="flex items-center sm:hidden md:hidden">
               <div className={cx("search")}>
                  <input
                     className={cx("search-input")}
                     spellCheck="false"
                     placeholder={t("header.search")}
                  />
                  <button className={cx("search-btn")}>
                     <FontAwesomeIcon icon={icons.search} />
                  </button>
               </div>
               <div>
                  <FontAwesomeIcon className={cx("user-btn")} icon={icons.user} />
               </div>
               <div>
                  <FontAwesomeIcon
                     className={cx("shopping-btn")}
                     icon={icons.shopping}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}

export default withTranslation()(Header);
