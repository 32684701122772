import { lazy } from "react";
import RouteProps from "../types/RouteProps";

const Home = lazy(() => import("../pages/Home"));
const Product = lazy(() => import("../pages/Product"));
const Shop = lazy(() => import("../pages/Shop"));

const LIST_PATH = {
   INDEX: "/",
   PRODUCT: "/product",
   SHOP: '/shop'
};

export const PUBLIC_ROUTES: RouteProps[] = [
   {
      path: LIST_PATH.INDEX,
      component: Home,
   },
   {
      path: LIST_PATH.PRODUCT,
      component: Product,
   },
   {
      path: LIST_PATH.SHOP,
      component: Shop,
   },
];
