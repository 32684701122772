import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import { withTranslation } from "react-i18next";

import Footer from "../Footer";
import Header from "../Header";

import { LANGUAGES, LANGUAGE_KEY } from "../../../../utils/constants";

import styles from "./style.module.scss";
import LocalStorageServices from "../../../../services/LocalStorageServices";
import Loading from "../../Loading";
const cx = classNames.bind(styles);

interface DefaultLayoutProps {
   children: JSX.Element;
   i18n: any;
}

function DefaultLayout({ children, i18n }: DefaultLayoutProps) {
   const [language, setLanguage] = useState<string>(LocalStorageServices.get(LANGUAGE_KEY) || LANGUAGES.VN)
   const [loading, setLoading] = useState<Boolean>(false)

   useEffect(() => {
      const languageLocal = LocalStorageServices.get(LANGUAGE_KEY)
      
      if (languageLocal === LANGUAGES.EN) {
         i18n.changeLanguage(language)
      } else if (languageLocal === LANGUAGES.VN) {
         i18n.changeLanguage(language)
      }
      
      LocalStorageServices.save(LANGUAGE_KEY, language)

   }, [language])

   const handleChangeLanguage = (language: string) => {
      setLoading(true)
      setTimeout(() => {
         setLanguage(language)
         i18n.changeLanguage(language)
         setLoading(false)
      }, 1000)
      // document.location.reload()
   }

   const props = {
      language,
      handleChangeLanguage
   }

   if (loading) {
      return <Loading />
   }

   return (
      <div className={cx("wrapper")}>
         <Header {...props} />
         {children}
         <Footer />
      </div>
   );
}

export default withTranslation()(DefaultLayout);
