import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import vn from "./resources/languages/vn/vn.json"
import en from "./resources/languages/en/en.json"

const resources = {
    en: {
        translation: en
    },
    vi: {
        translation: vn
    }
}

i18n
    .use(initReactI18next)
    .init({
        debug: true,
        lng: "vi",
        fallbackLng: 'vi',
        interpolation: {
            escapeValue: false,
        },
        resources
    });

export default i18n;