import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { withTranslation } from 'react-i18next'

import DefaultLayout from "./commons/components/layouts/DefaultLayout";
import Loading from "./commons/components/Loading";
import { PUBLIC_ROUTES } from "./routes";
import './i18n'
import "./App.scss";

function App() {
   return (
      <>
         <Suspense fallback={<Loading />}>
            <Routes>
               {PUBLIC_ROUTES.map((route, index) => {
                  const Layout = DefaultLayout;
                  return route.component ? (
                     <Route
                        key={index}
                        path={route.path}
                        element={
                           <Layout>
                              <route.component />
                           </Layout>
                        }
                     />
                  ) : null;
               })}
            </Routes>
         </Suspense>
      </>
   );
}

export default withTranslation()(App);
