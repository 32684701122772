const LocalStorageServices = {
    save(key: string, data: any) {
        localStorage.setItem(key, data ? JSON.stringify(data): data)
    },

    get(key: string) {
        if (localStorage === null || typeof key !== 'string') {
            throw new Error("Fail to read object to localStorage");
        }
        const dataJson = localStorage?.getItem(key);
        return dataJson !== null ? JSON.parse(dataJson) : null;
    }
}

export default LocalStorageServices