const ENTER_KEY = 13;

const LANGUAGES = {
    VN: 'vi',
    EN: 'en'
}

const LANGUAGE_KEY = 'language'

export {
    ENTER_KEY,
    LANGUAGES,
    LANGUAGE_KEY
};
