import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
   faLocationDot,
   faPhone,
   faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import {
   faFacebookF,
   faYoutube,
   faInstagram
} from "@fortawesome/free-brands-svg-icons"

const icons = {
   faLocationDot,
   faPhone,
   faEnvelope,
   faFacebookF,
   faYoutube,
   faInstagram
} as any

function Footer() {
   return (
      <div>
         <div className="py-4 px-4 bg-stone-100 text-base font-thin xl:block">
            <span>Hỗ trợ / Mua hàng: </span><b className="text-red-500 hover:text-red-600">0933 800 190</b>
         </div>
         <div className="w-full px-4 ">
            <div className="flex">
               <div className="w-6/12 sm:w-full">
                  <h5 className="text-2xl">Hệ thống cửa hàng</h5>
                  <div className="flex">
                     <div className="w-5 ">
                        <FontAwesomeIcon className="text-lg" icon={icons.faLocationDot} />
                     </div>
                     <div>
                        <div>
                           <h5 className="mb-0">Chi nhánh Hồ Chí Minh</h5>
                           <div><b>Quận 10</b> - 561 Sư Vạn Hạnh, Phường 13.</div>
                        </div>
                        <div>
                           <div><b>Quận 10</b> - 561 Sư Vạn Hạnh, Phường 13.</div>
                        </div>
                        <div>
                           <div><b>Quận 10</b> - 561 Sư Vạn Hạnh, Phường 13.</div>
                        </div>
                        <div>
                           <div><b>Quận 10</b> - 561 Sư Vạn Hạnh, Phường 13.</div>
                        </div>
                        <div>
                           <div><b>Quận 10</b> - 561 Sư Vạn Hạnh, Phường 13.</div>
                        </div>
                     </div>
                  </div>
                  <div className="flex">
                     <div className="w-5">
                        <FontAwesomeIcon className="text-lg" icon={icons.faLocationDot} />
                     </div>
                     <div>
                        <div>
                           <h5 className="mb-0">Chi nhánh Hồ Chí Minh</h5>
                           <div><b>Quận 10</b> - 561 Sư Vạn Hạnh, Phường 13.</div>
                        </div>
                     </div>
                  </div>
                  <div className="flex">
                     <div className="w-5">
                        <FontAwesomeIcon className="text-lg" icon={icons.faLocationDot} />
                     </div>
                     <div>
                        <div>
                           <h5 className="mb-0">Chi nhánh Hồ Chí Minh</h5>
                           <div><b>Quận 10</b> - 561 Sư Vạn Hạnh, Phường 13.</div>
                        </div>
                     </div>
                  </div>
                  <div className="flex">
                     <div className="w-5">
                        <FontAwesomeIcon className="text-lg" icon={icons.faLocationDot} />
                     </div>
                     <div>
                        <div>
                           <h5 className="mb-0">Chi nhánh Hồ Chí Minh</h5>
                           <div><b>Quận 10</b> - 561 Sư Vạn Hạnh, Phường 13.</div>
                        </div>
                     </div>
                  </div>
                  <div className="flex">
                     <div className="w-5">
                        <FontAwesomeIcon className="text-lg" icon={icons.faPhone} />
                     </div>
                     <div>
                        <h5 className="mb-0">Chi nhánh Hồ Chí Minh</h5>
                     </div>
                  </div>
                  <div className="flex">
                     <div className="w-5">
                        <FontAwesomeIcon className="text-lg" icon={icons.faEnvelope} />
                     </div>
                     <div>
                        <h5 className="mb-0">csteamdcs@gmail.com</h5>
                     </div>
                  </div>
               </div>
               <div className="w-3/12 sm:w-full">
                  <div>
                     <h5 className="text-2xl">Mạng xã hội</h5>
                     <div>
                        <ul>
                           <li className="inline-block mr-1.5 ">
                              <p className="block text-center border border-solid rounded-full border-zinc-300">
                                 <FontAwesomeIcon className="text-lg" icon={icons.faFacebookF} />
                              </p>
                           </li>
                           <li className="inline-block mr-1.5 ">
                              <p className="block text-center border border-solid rounded-full border-zinc-300">
                                 <FontAwesomeIcon className="text-lg" icon={icons.faYoutube} />
                              </p>
                           </li>
                           <li className="inline-block mr-1.5 ">
                              <p className="block text-center border border-solid rounded-full border-zinc-300">
                                 <FontAwesomeIcon className="text-lg" icon={icons.faInstagram} />
                              </p>
                           </li>
                        </ul>
                     </div>
                  </div>
                  <div>
                     <h5 className="text-2xl">Chính sách</h5>
                     <ul>
                        <li className="list-disc">Chính sách bảo mật</li>
                        <li className="list-disc">FAQ</li>
                        <li className="list-disc">Chính sách Thẻ Thành viên</li>
                        <li className="list-disc">Chính sách Bảo hành {'&'} Đổi trả</li>
                     </ul>
                  </div>
               </div>
               <div className="w-3/12  sm:w-full">
                  <div>
                     <h5 className="text-2xl">Fanpage</h5>
                     <ul>
                        <li className="list-disc">Chính sách bảo mật</li>
                        <li className="list-disc">FAQ</li>
                        <li className="list-disc">Chính sách Thẻ Thành viên</li>
                        <li className="list-disc">Chính sách Bảo hành {'&'} Đổi trả</li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div className="text-center py-5 border-solid border-t border-slate-100">
            <span>Copyright © 2020 Dirty Coins Studio. Powered by Sapo</span>
         </div>
      </div>
   );
}

export default Footer;
