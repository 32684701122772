import { Space, Spin } from 'antd';
import "./style.scss";

function Loading() {
   return <div className="loading-box">
      <Space size="middle">
         <Spin size="large" />
      </Space>
   </div>;
}

export default Loading;
